//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, required} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

import Formatter from "@/components/libraries/Formatter";
const formatter = new Formatter();

//import axios from "axios";
//import WeekScheduler from "@/components/WeekScheduler";
//import PrinterPrice from "@/components/management/printers/PrinterPrice";
//import PrinterCustomSchedule from "./PrinterCustomSchedule.vue";
//import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";

//const copyArrObj = new CopyArrayAndObject();

export default {
  name: "EditBanner",
  components: { DatePicker },
  data(){
    return {
      banner: {
        id: 0,
        name: '',
        codename: '',
        img_url: '',
        file: [],
        showStart: '',
        showEnd: '',
        type: '',
        url: '',
        action: '',
        placement: '',
        is_on: 0
      },
      submitted: false,
    }
  },
  props: {
    setDefault: Object,
    submit: Number
  },
  watch: {
    setDefault: function(val){
      this.banner.id = val.id;
      this.banner.name = val.name;
      this.banner.codename = val.codename;
      this.banner.img_url = val.img_url;

      this.banner.showStart = formatter.phpDatetimeToJsObject(val.start_dt);
      this.banner.showEnd = formatter.phpDatetimeToJsObject(val.stop_dt);
      this.banner.type = val.type;
      this.banner.url = val.url;
      this.banner.action = val.action;
      this.banner.placement = val.placement;
      this.banner.is_on = val.is_on;
    },
    submit: function(val){
      if(val > 0){
        this.submitCreate();
      }
    }
  },
  computed: {

  },
  validations: {
    banner: {
      name: { required, minLength: minLength(3), maxLength: maxLength(255) },
      codename: { required, minLength: minLength(3), maxLength: maxLength(60) },
      type: { required, minLength: minLength(3), maxLength: maxLength(60) },
      placement: { required, minLength: minLength(3), maxLength: maxLength(60) },
    }
  },
  methods: {
    previewFiles(event) {
      this.banner.file = event.target.files;
    },
    submitCreate(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.banner.$touch();
      if(!this.$v.banner.$invalid){
        this.$emit('submitCreate', this.banner);
      }
    },
  },
  created() {

  }

};
