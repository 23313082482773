//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";


//import { minValue, maxValue, required} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from '../../../components/Popup.vue';
import CreateBanner from '../../../components/management/banners/CreateBanner.vue';

import Formatter from "@/components/libraries/Formatter";
import EditBanner from '../../../components/management/banners/EditBanner.vue';
const formatter = new Formatter();
//import Paginator from "@/components/Paginator";
//import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
   // Paginator,
    Layout,
    PageHeader,
    CreateBanner,
    EditBanner
  },
  data() {
    return {
      banners: [],
      addBannerPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      addBannerSubmit: 0,

      editBannerPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editBannerObj: {},
      editBannerSubmit: 0,

      submitted: false,
      title: this.$t('menuitems.management.list.banners'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.banners'),
          active: true
        }
      ],
      data: [],
    };
  },
  // validations: {
  //   sumPoint: { required, minValue: minValue(1), maxValue: maxValue(5000) }
  // },
  computed: {

  },
  watch: {
    
  },
  methods: {
    closeEditPopup(){
      this.editBannerPopup.show = false;
    },
    showEditPopup(obj){
      this.editBannerPopup.show = true;
      this.editBannerObj = obj;
    },
    editPopupSubmit(){
      this.editBannerSubmit++;
    },
    closeAddPopup(){
      this.addBannerPopup.show = false;
    },
    showAddPopup(){
      this.addBannerPopup.show = true;
    },
    addPopupSubmit(){
      this.addBannerSubmit++;
    },
    phpDatetimeToRus(datetime){
      return formatter.datetimeToRus(datetime);
    },
    editBann(data){
      let formData = new FormData();
      for(let key in data){
        if( key === 'file'){
          formData.append(key, data[key][0]);
        }else if( key == 'showStart' || key == 'showEnd'){
          let dtRes = formatter.jsObjectToPhpDatetime(data[key]);
          formData.append(key, dtRes);
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/banner/update`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getBanners();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    toggleOn(bannerId){
      let formData = new FormData();
      formData.append('id', bannerId);
      
      axios
          .post(`/v1/banner/toggle-on`, formData)
          .then(() => {
            this.getBanners();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    createBann(data){
      let formData = new FormData();
      for(let key in data){
        if( key === 'file'){
          formData.append(key, data[key][0]);
        }else if( key == 'showStart' || key == 'showEnd'){
          let dtRes = formatter.jsObjectToPhpDatetime(data[key]);
          formData.append(key, dtRes);
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/banner/create`, formData)
          .then(() => {
            this.closeAddPopup();
            this.getBanners();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getBanners(){
      axios
          .get(`/v1/banner/get`)
          .then(resp => {
            this.banners = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getBanners();
  }
};
