//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, required} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

//import axios from "axios";
//import WeekScheduler from "@/components/WeekScheduler";
//import PrinterPrice from "@/components/management/printers/PrinterPrice";
//import PrinterCustomSchedule from "./PrinterCustomSchedule.vue";
//import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";

//const copyArrObj = new CopyArrayAndObject();

export default {
  name: "CreateBanner",
  components: { DatePicker },
  data(){
    return {
      banner: {
        name: '',
        codename: '',
        file: [],
        showStart: '',
        showEnd: '',
        type: '',
        url: '',
        action: '',
        placement: ''
      },
      submitted: false,
    }
  },
  props: {
    submit: Number
  },
  watch: {
    submit: function(val){
      if(val > 0){
        this.submitCreate();
      }
    }
  },
  computed: {

  },
  validations: {
    banner: {
      name: { required, minLength: minLength(3), maxLength: maxLength(255) },
      codename: { required, minLength: minLength(3), maxLength: maxLength(60) },
      type: { required, minLength: minLength(3), maxLength: maxLength(60) },
      placement: { required, minLength: minLength(3), maxLength: maxLength(60) },
    }
  },
  methods: {
    previewFiles(event) {
      this.banner.file = event.target.files;
    },
    submitCreate(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.banner.$touch();
      if(!this.$v.banner.$invalid){
        this.$emit('submitCreate', this.banner);
      }
    },
  },
  created() {

  }

};
